.recording:after {
  content: " ";
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  background: red;
  border-radius: 50%;
  top: 15px;
  left: 5px;
}

.audio {
  width: inherit;
  height: inherit;
}