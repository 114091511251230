.react-contextmenu {
  min-width: 80px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 10px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  outline: none;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 10ms ease!important;
  -o-transition: opacity 10ms ease!important;
  transition: opacity 10ms ease!important
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto
}

.react-contextmenu-item {
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer
}


.react-contextmenu-item.react-contextmenu-item--selected.react-contextmenu-item--disabled:hover {
  color: #000;
}

.react-contextmenu-item.react-contextmenu-item--active,.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(88, 130, 181)), to(rgb(65, 102, 147)));
  text-decoration: none
}

.react-contextmenu-item.react-contextmenu-item--disabled,.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  color: #878a8c;
  background-color: transparent;
  border-color: rgba(0,0,0,.15)
}

.react-contextmenu-item--divider {
  margin-bottom: 3px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0,0,0,.15);
  cursor: inherit
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15)
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0
}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item:after {
  content: "\25B6";
  display: inline-block;
  position: absolute;
  right: 7px
}

.example-multiple-targets:after {
  content: attr(data-count);
  display: block
}

nav.react-contextmenu.react-contextmenu--visible {
  z-index: 3
}
