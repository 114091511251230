.list-container { 
  overflow:auto; 
}

.list {
  display:grid; 
  grid-auto-columns: auto;
}

.list .listItem,
.list .listItemNoHover {
  display: flex;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
}

.list .listItem .listItemChild,
.list .listItemNoHover .listItemChild {
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.list .listItem:hover {
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(235, 235, 235)), to(rgb(213, 213, 213)))!important;
}

