.carousel-wrap {
    position: relative;
    background-color:#E7E7E7;
}

.carousel * {
    outline: none;
}

.carousel-wrap:last-child {
    margin-bottom: 0!important;
}

.carousel .button-container {
    padding: 5px;
    display: block;
}

.carousel .button-container button {
    width: 100%;
    display: inline-block;
    transform: scale(0.85);
    transition: transform 0.1s linear;
    word-wrap: break-word;
    overflow: hidden;
    cursor: pointer;
}

.carousel .button-container button.active {
    font-weight: bold;
}

.carousel .nav-buttons {
    position: absolute;
    top: 0;
    display: block;
    font-size: 50px;
    font-weight: lighter;
}

.carousel .nav-buttons:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.carousel .nav-buttons.back {
    left: 0;
}

.carousel .nav-buttons.next {
    right: 0;
}


.carousel.test-navigation .button-container button {
    font-size: 14px;
}

.carousel.task-navigation .button-container button {
    font-size: 12px;
}