.cba-timer-wrap {
  display: flex;
}

.cba-timer-wrap.column {
  flex-direction: column;
}

.cba-timer-wrap.column-reverse {
  flex-direction: column-reverse;
}

.cba-timer-wrap.row {
  flex-direction: row;
}

.cba-timer-wrap.row-reverse {
  flex-direction: row-reverse;
}

.cba-timer-wrap .cba-timer-animation {
  background: linear-gradient(-90deg, rgb(88, 130, 181) 0%, rgb(65, 102, 147) 100%) repeat scroll 0% 0%, rgb(0, 88, 159) none repeat scroll 0% 0%;
  height: 100%;
  width: 100%;
}

.cba-timer-wrap button {
  position: absolute;
  top: 0;
  left: 0;
}

.cba-timer-wrap .cba-timer-secondsLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
