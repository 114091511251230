.task-navigator {
    background-color: #a6a6a6;
    padding-bottom: 7px;
}

.task-navigator .test-navigation {
    background-color: #a6a6a6;
    margin-bottom: 0 !important;
}

.task-navigator .task-navigation {
    background-color: #f6f6f6;
    padding: 10px 0 5px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}


/* override button default styles */
.task-navigator button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding : 5px;
    word-wrap: break-word;
}

.task-navigator .test-navigation button {
    background-color: #f6f6f6;
    font-size: 15px;
}

.task-navigator .task-navigation button {
    background-color: #e6e6e6;
    padding: 5px;
    margin-left: 20px;
    font-size: 12px;
    transform: scale(0.85);
    transition: transform 0.1s linear;
    overflow: hidden;
}

.task-navigator .task-navigation button.active {
    background-color: #a6a6a6;
    color: #f6f6f6;
    font-weight: bold;
}

.task-navigator button.nav-buttons {
    width: 25px;
    font-size: 50px;
    background-color: #a6a6a6;
    color: #f6f6f6;
}

.task-navigator .nav-buttons:disabled {
    opacity: 1;
}
